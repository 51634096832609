import { GET_PODCASTS, ADD_PODCASTS, REMOVE_PODCASTS } from '../actions/podcasts';

const initialState = { list: [] };

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PODCASTS:
            return { ...state, list: action.data }; // Шинэ датагаар шууд солих

        case ADD_PODCASTS:
            return { ...state, list: [...state.list, ...action.data] }; // Өмнөх дататай нийлүүлж хадгалах
        // return { ...state, list: action.data };
        case REMOVE_PODCASTS:
            return { ...state, list: state.list.filter((book) => book.id !== action.data) };

        default:
            return state;
    }
};

export default [reducer, initialState];
