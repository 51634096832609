import combineReducers from 'react-combine-reducers';
import auth from './auth';
import menu from './menu';
import news from './news';
import ebooks from './ebooks';
import podcasts from './podcasts';

const [reducer, initialState] = combineReducers({
    menu,
    news,
    ebooks,
    podcasts,
    auth,
});

export { reducer, initialState };
