import {
    Image as ImageIcon,
    Menu as MenuIcon,
    GitPullRequest as GitPullRequestIcon,
    Sun as SunIcon,
    List as ListIcon,
    Columns as ColumnsIcon,
} from 'react-feather';

const adminMenu = [
    {
        href: '/admin/news',
        icon: ColumnsIcon,
        title: 'Мэдээ мэдээлэл',
    },
    {
        href: '/admin/ebooks',
        icon: ColumnsIcon,
        title: 'Цахим ном',
    },
    {
        href: '/admin/lectures',
        icon: ColumnsIcon,
        title: 'Клиникийн лекц',
    },
    {
        href: '/admin/podcasts',
        icon: ImageIcon,
        title: 'Подкаст',
    },
    // {
    //     href: '/admin/projects',
    //     icon: ColumnsIcon,
    //     title: 'Төсөл хөтөлбөр',
    // },
    // {
    //     href: '/admin/works',
    //     icon: ColumnsIcon,
    //     title: 'Бүтээлч ажил',
    // },
    // {
    //     href: '/admin/tenders',
    //     icon: ImageIcon,
    //     title: 'Тендер',
    // },
    // {
    //     href: '/admin/togtool',
    //     icon: ImageIcon,
    //     title: 'Захирамж, тогтоол',
    // },
    // {
    //     href: '/admin/request',
    //     icon: GitPullRequestIcon,
    //     title: 'Орох, гарах хүсэлт',
    // },
    {
        href: '/admin/menus/0',
        icon: MenuIcon,
        title: 'Цэс удирдах',
    },
    {
        href: '/admin/posts/0',
        icon: MenuIcon,
        title: 'Цэсний контент',
    },
    // {
    //     href: '/admin/sliders',
    //     icon: ImageIcon,
    //     title: 'Слайдер зураг',
    // },
    // {
    //     href: '/admin/zar',
    //     icon: SunIcon,
    //     title: 'Зар оруулах',
    // },
    // {
    //     href: '/admin/poll',
    //     icon: ListIcon,
    //     title: 'Санал асуулга',
    // },
    {
        href: '/admin/covers',
        icon: ImageIcon,
        title: 'Ковер зураг',
    },
    // {
    //     href: '/admin/donation',
    //     icon: ColumnsIcon,
    //     title: 'Хандивын мэдээлэл',
    // },
    // {
    //     href: '/admin/banks',
    //     icon: ColumnsIcon,
    //     title: 'Дансны мэдээлэл',
    // },
    {
        href: '/admin/links',
        icon: ColumnsIcon,
        title: 'Вэб холбоосууд',
    },
];

export default adminMenu;
