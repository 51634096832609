const fetch = require('utils/fetchRequest');
export const GET_PODCASTS = 'GET_PODCASTS';
export const ADD_PODCASTS = 'ADD_PODCASTS';
export const REMOVE_PODCASTS = 'REMOVE_PODCASTS';
export const getPodcasts = async (query) => {
    try {
        const response = await fetch.get('podcasts?' + query);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const getCats = async () => {
    try {
        const response = await fetch.get('podcasts/cats');
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const getOnePodcasts = async (id) => {
    try {
        const response = await fetch.get('podcasts/' + id);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const addPodcasts = async (datas) => {
    const result = await fetch.post('podcasts', datas);
    return result;
};

export const editPodcasts = async (datas, id) => {
    const result = await fetch.put('podcasts/' + id, datas);
    return result;
};

export const deletePodcasts = async (id) => {
    const result = await fetch.destroy('podcasts/' + id);
    return result;
};
