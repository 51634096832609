import React, { useEffect } from 'react';
// import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useRouter } from 'next/router';

import theme from '../constants/theme';
import { ContextState } from '/context';
import AdminLayout from '/layouts/AdminLayout';
import MainLayout from '/layouts/MainLayout';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import '../styles/globals.css';

import ProgressBar from '@badrap/bar-of-progress';
import previewImage from 'public/logo.png';

const progress = new ProgressBar({
    size: 3,
    color: '#38a169',
    className: 'bar-of-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

export default function MyApp(props) {
    const { Component, pageProps } = props;
    const Layout = Component.Layout || AdminLayout;

    const router = useRouter();
    const { asPath, pathname, query } = router;

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    if (pathname.includes('404')) return 'Not found';

    return (
        <React.Fragment>
            <Head>
                <title>Анагаах Ухааны Хүрээлэн</title>
                <link rel="shortcut icon" href="/logo.png" />
                {/* <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" /> */}
                <meta charSet="utf-8" />
                <meta property="og:title" content="Anagaakh Ukhaanii Khureelen (IMSM)" key="title" />
                <meta property="og:title" content="Т.Шагдарсүрэнгийн нэрэмжит Анагаах Ухааны Хүрээлэн (IMSM)" key="ogtitle" />
                <meta property="og:site_name" content="imsm.mn" key="ogsitename" />
                <meta name="keywords" content="Анагаах Ухааны Хүрээлэн, imsm, Khureelen, Hureelen, Т.Шагдарсүрэн, Anagaah Uhaan" />

                <meta property="og:description" content="Т.Шагдарсүрэнгийн нэрэмжит Анагаах Ухааны Хүрээлэн албан ёсны вэб сайт" key="ogdesc" />
                <meta name="robots" content="index, follow" />

                {/* <meta property="og:image" content={previewImage} key="ogimage" /> */}
                {/* <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                /> */}
            </Head>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ContextState>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                    {/* <MessengerCustomerChat
                        pageId="158689140995237"
                        appId="556518665768761"
                        htmlRef="asd"
                    /> */}
                </ContextState>
            </ThemeProvider>
        </React.Fragment>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};
