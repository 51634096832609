const fetch = require('utils/fetchRequest');

export const GET_EBOOKS = 'GET_EBOOKS';
export const ADD_EBOOKS = 'ADD_EBOOKS';
export const REMOVE_EBOOKS = 'REMOVE_EBOOKS';

export const getEbooks = async (query) => {
    try {
        const response = await fetch.get('ebooks?' + query);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const getCats = async () => {
    try {
        const response = await fetch.get('ebooks/cats');
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const getOneEbooks = async (id) => {
    try {
        const response = await fetch.get('ebooks/' + id);
        return response.data;
    } catch (err) {
        return { error: 'Сервертэй холбогдож чадсангүй' };
    }
};

export const addEbooks = async (datas) => {
    const result = await fetch.post('ebooks', datas);
    return result;
};

export const editEbooks = async (datas, id) => {
    const result = await fetch.put('ebooks/' + id, datas);
    return result;
};

export const deleteEbooks = async (id) => {
    const result = await fetch.destroy('ebooks/' + id);
    return result;
};
